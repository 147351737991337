
  var owlMedia = $('.owl-carousel-media');
$( document ).ready(function() {

console.log(owlMedia);

  owlMedia.owlCarousel({
    dot:false,
    margin:0,
    nav:false,
    loop:true,
    autoplay:true,
    translated: {
      
    },
  responsive:{
              320:{
                  items:1,
                  nav: true,
                  navText: '',
              },
              500:{
                  items:1,
                  nav: true,
                  navText: '',
              },
              768:{
                  items:2,
                  nav: true,
                  navText: '',
    },
              992:{
                  items:4,
                  nav: true,
                  navText: '',
    }
  } });

});
